import { LOCALES } from "../locales";

export const ru = {
  [LOCALES.RUSSIAN]: {
    "Jack": "Жак-Ив Кусто",
    "acc_pic": "фото аккаунта",
    "add_place": "Создать",
    "add_place_btn": "Кнопка добавления карточки места",
    "alrdy_registered": "Уже зарегистрированы? ",
    "app_title": "Место",
    "avatar_addr_err": "Введите адрес аватара.",
    "avatar_edit_btn": "Кнопка редактирования аватара",
    "avatar_link": "Ссылка на аватар",
    "blank": "blank",
    "card_delete_button": "Кнопка удаления карточки места",
    "card_like_btn": "Кнопка лайк сердечко карточки места",
    "edit_profile": "Редактировать профиль",
    "name_error": "Пожалуйста введите верное имя.",
    "desc_error": "Пожалуйста введите верное описание.",
    "email_error": "Пожалуйста введите верный email адрес.",
    "url_error": "Пожалуйста введите верный адрес.",
    "pwd_error": "Пожалуйста введите верный пароль.",
    "err_icon": "иконка ошибки",
    "error_msg": "Произошла ошибка!",
    "img_link": "Ссылка на картинку",
    "lang": "ru",
    "language": "Язык: ",
    "login": "Вход",
    "logo": "логотип",
    "logout": "Выйти",
    "missed_field_error": "Вы пропустили это поле.",
    "my_name": "Максим Толстокорый",
    "new_place": "Новое место",
    "noscript": "Вам нужно включить Javascript, чтобы запустить это приложение.",
    "ocean_explorer": "Исследователь океана",
    "password": "Пароль",
    "place_name": "Название",
    "photo": "Фото",
    "popup_close_btn": "Кнопка закрытия попапа",
    "popup_err_msg": "Что-то пошло не так! Попробуйте ещё раз.",
    "profile_edit_btn": "Кнопка редактирования профиля",
    "profile_success": "Профиль успешно обновлён!",
    "register": "Зарегистрироваться",
    "register_success": "Вы успешно зарегистрировались!",
    "register_success_msg": "Вы успешно зарегистрировались!",
    "registration": "Регистрация",
    "save": "Сохранить",
    "saving": "Сохранение...",
    "success_icon": "иконка успеха",
    "sure?": "Вы уверены?",
    "to_login": "Войти",
    "update_avatar": "Обновить аватар",
    "yes": "Да"
  }
};
